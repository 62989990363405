<template>
  <div class="m-6">
    <div class="button is-primary is-fullwidth py-5">
      <a :href="link" target="_blank" rel="noopener" class="has-text-weight-semibold">
        {{ text }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    link() {
      return this.currentDirectory?.search_cta_link ?? '';
    },
    text() {
      return this.currentDirectory?.search_cta_text ?? '';
    },
  },
};
</script>
